import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button/Button';
import { useNavigate } from 'react-router-dom';
import { ADD_VENDOR_INVENTORY_STATES, ADVANCE_FILTER_TYPE, HTTP_STATUS, IPagination } from '../../../../utils/types';
import ProductViewTemplate from './ProductView.template';
import Search from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import TextFieldV2 from '../../../atoms/Input/TextFieldV2';
import { GENERIC_FILTER_SECTIONS, PRODUCT_SECTION, initialPaginationValues } from '../../../../utils/constant';
import HoverButton from '../../../atoms/Button/HoverButton';
import advanceFilterIcon from '../../../../assets/icons/advanceFilterIcon.svg';
import { AddVendorInventorySectionProp, IAddVendorInventoryForm } from './AddNewVendorInventory.template';
import { useVendorInventoryService } from '../../../../services/useVendorInventoryService';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { FormHelperText } from '@mui/material';
import { IDetails } from '../../VendorManagement/ProductCatalogueView.template';
import UpcMpcSearchDialogModal, { IMaterialFilterData } from '../../GenericSearchDialog/GenericSerachDialog.template';
import { IconButton } from '@mui/joy';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { TablePaginationActionsProps } from '../../Catalogue/CatalogueList.template';
import { TablePagination, useTheme, Input } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import { IProductSearchParams, useGenericSearchService } from '../../../../services/useGenericSearchService';

export interface IRequestBodyValues {
    upcIds: (string | number)[] | null;
    brandIds: (string | number)[] | null;
}

export interface IProductData {
    id: number | null;
    productId?:number | null,
    skuCode: string;
    productName: string;
    standardProductDetails?: IDetails;
    length?: string;
    temper?: string;
    thickness?: string;
    width?: string;
}

const useStyles = createUseStyles((theme: any) => ({
    webContainer: {
        display: "grid"
    },
    activatedProgressBar: {
        backgroundColor: theme.palette.backgroundV2.secondary.secondary950
    },
    activeProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary400
    },
    UnTouchProgressBar: {
        backgroundColor: theme.palette.borderV2.tertiary.tertiary100
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    selectBox: {
        border: `1px solid ${theme.palette.backgroundV2.tertiary.tertiary100}`,
    },
    textHeading: {
        color: theme.palette.textV2.primary.primary950
    },
    search: {
        margin: "0",
        background: theme.palette.background.primaryLight,
    },
    searchIcon: {
        color: theme.palette.icon.primaryLight
    },
    paginationTable: {
        "& .MuiTablePagination-selectLabel": {
            color: "#333",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "20.3px",
            letterSpacing: "0.21px"
        },
        "& .MuiTablePagination-input": {
            borderRadius: '8px',
            border: '1px solid #E6E6E6',
            width: '80px',
            paddingRight: '10px',
            marginRight: "24px",
            height: "30px"
        },
        "& .MuiTablePagination-displayedRows": {
            color: "#333",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "20.3px",
            letterSpacing: "0.21px"
        },
        "& .MuiTablePagination-spacer": {
            flex: 0
        },
        "& .MuiToolbar-root": {
            paddingLeft: "0px !important",
            paddingRight: "0px",
            width: "100%"
        },
    },
    paginationComponent: {
        color: theme.palette.pagination.text.secondaryLight,
        fontWeight: 500,
        fontSize: "14px",
        width: "100%",
        justifyContent: 'end',
    },
    inputBar: {
        width: "54px",
        height: "28px",
        borderRadius: "8px",
        border: `1px solid ${theme.palette.border.tritiaryLight}`,
        paddingLeft: "16px"
    }

}));

const SelectProductTemplate: React.FC<AddVendorInventorySectionProp> = ({ formik, setCurrentSectionTo }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const genericSearchService = useGenericSearchService();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const { user } = useAuthenticatedUser();
    const [filterSection, setFilterSections] = useState<string[]>([]);
    const [upcFilterData, setUpcFilterData] = useState<IMaterialFilterData>({});
    const [requestBodyValues, setRequestBodyValuesTo] = useState<IRequestBodyValues>({ upcIds: null, brandIds: null });
    const [productsData, setProductsData] = useState<IProductData[]>([{ id: null,productId:null, skuCode: '', productName: '' }]);
    const vendorInventoryService = useVendorInventoryService();
    const { showSnackbar } = useSnackbar();
    const [pagination, setPagination] = useState<IPagination>(initialPaginationValues);

    const handleCancel = () => {
        navigate('/dashboard/inventory-management');
    };
    const handleBack = () => {
        setCurrentSectionTo(ADD_VENDOR_INVENTORY_STATES.PRODUCT_TYPE);
    }
    const onNext = async (event?: React.FormEvent) => {
        if(!formik?.values?.productId){
            showSnackbar("error", "Please select a product")
            return;
        }
        let params: IProductSearchParams = {
            page: 0,
            size: 10,
            sort: 'createdAt,desc',
            classType: "STANDARD"
        }
        const filter = {
            productCode: formik?.values?.productId ? [formik?.values?.productId] : null,
        }
        if(user?.businessId){
            const selectedUpcBrandResponse = await genericSearchService.searchProductByvendorId(user?.businessId, params, filter);
            const data = selectedUpcBrandResponse?.data?.data?.content?.[0];
            setCurrentSectionTo(ADD_VENDOR_INVENTORY_STATES.ADD_USN);
        }
        else{
            showSnackbar("error", "User is not logged in.")
        }
    }

    const handleFilterClear = () => {
        setUpcFilterData({});
    }

    const onFilterSubmit = async () => {
        setRequestBodyValuesTo({ upcIds: upcFilterData?.productIds ?? null, brandIds: null });
        if(!upcFilterData?.productIds?.[0]){
            showSnackbar("error", "Please select a product")
            return;
        }
        formik.setFieldValue('productId', Number(upcFilterData?.productIds?.[0]));
        let params: IProductSearchParams = {
            page: 0,
            size: 10,
            sort: 'createdAt,desc',
            classType: "STANDARD"
        }
        const filter = {
            productCode: Number(upcFilterData?.productIds?.[0]) ? [Number(upcFilterData?.productIds?.[0])] : null,
        }
        if(user?.businessId){
            const selectedUpcBrandResponse = await genericSearchService.searchProductByvendorId(user?.businessId, params, filter);
            const data = selectedUpcBrandResponse?.data?.data?.content?.[0];
            setCurrentSectionTo(ADD_VENDOR_INVENTORY_STATES.ADD_USN);
        }
        else{
            showSnackbar("error", "User is not logged in.")
        }
    }
    const clearAllData = () => {
        setUpcFilterData({});
        setRequestBodyValuesTo({ upcIds: null, brandIds: null });
    }

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPagination((prevPagination: IPagination) => ({
            ...prevPagination,
            page: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPagination((prevPagination) => ({
            ...prevPagination,
            size: newRowsPerPage
        }));
    };

    function TablePaginationActions(props: TablePaginationActionsProps) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;
        const [inputPage, setInputPage] = useState(page + 1);

        const handleFirstPageButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>,
        ) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        const handleInputPageChange = (event: any) => {
            setInputPage(parseInt(event.target.value, 10));
        };
        const handleInputBlur = (event: any) => {
            onPageChange(event, inputPage - 1);
        };

        return (
            <div className={`flex gap-x-6 ${classes.paginationComponent}`}  >
                <div className="flex gap-x-2.5">
                    <div className='my-auto'>Page</div>
                    <div className='my-auto'>
                        <Input
                            type="number"
                            value={inputPage}
                            onChange={handleInputPageChange}
                            onBlur={handleInputBlur}
                            disableUnderline={true}
                            inputProps={{ min: 1, max: Math.ceil(count / rowsPerPage) }}
                            style={{ width: '54px', height: "28px", borderRadius: '8px', border: '1px solid #E6E6E6', paddingLeft: '16px' }}
                        />
                    </div>
                    <div className='my-auto'>of {Math.ceil(count / rowsPerPage)}</div>
                </div>

                <div className={`flex`}>
                    <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                    </IconButton>
                    <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                    </IconButton>
                    <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </IconButton>
                    <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                    </IconButton>
                </div>
            </div>
        );
    }

    const getVedorProduct = async () => {
        if (user?.businessId) {
            try {
                const ProductsResponse = await vendorInventoryService.getAllProducts(user?.businessId , { classType: "STANDARD", page: pagination.page, size: pagination.size, search: searchTerm });
                if (ProductsResponse.status === HTTP_STATUS.OK) {
                    const productData = ProductsResponse?.data?.data?.content;
                    setProductsData(productData);
                    setPagination((prevPagination: IPagination) => ({
                        ...prevPagination,
                        totalPages: ProductsResponse?.data?.data?.totalPages,
                        totalRecords: ProductsResponse?.data?.data?.totalElements
                    }));
                }
            } catch (error) {
                showSnackbar('error', 'Business Profile fetch failed');

            }
        }
    };
    useEffect(() => {
        getVedorProduct();
    }, [pagination.page, pagination.size, searchTerm]);

    useEffect(() => {
        setFilterSections([GENERIC_FILTER_SECTIONS.UPC, GENERIC_FILTER_SECTIONS.MATERIAL_FILTER, GENERIC_FILTER_SECTIONS.OTHER]);
    }, [])


    const handleChange = (event: any) => {
        formik.setFieldValue('productId', event.target.value);
    }
    return (
        <div className={`${classes.webContainer} grid gap-y-8 `}>
            <div className={`w-full m-auto flex justify-between gap-x-2 text-var(--black, #4D4D4D) text-center text-sm `}>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activatedProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto font-semibold`}>Select Product Type </div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Select Product</div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.UnTouchProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Add USN Details </div>
                    </div>
                    <div className={`${classes.UnTouchProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.UnTouchProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Review</div>
                    </div>
                    <div className={`${classes.UnTouchProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>

            <div className=' grid gap-4 '>
                <div className='flex  justify-between'>
                    <div className=' grid gap-1/2'>
                        <text className={`${classes.textHeading}text-base font-medium`}> Select Product Type</text>
                        <text className={`${classes.barHeading}text-xs font-normal`}> Please select the product you want to add</text>
                    </div>
                    <div className='flex gap-1'>
                        <TextFieldV2
                            className=''
                            size="medium"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            InputProps={{
                                className: "px-1 h-11 text-base ",
                                startAdornment: (
                                    <InputAdornment position="start" className='px-3'>
                                        <Search className={classes.searchIcon} />
                                    </InputAdornment>
                                )
                            }}
                        />

                        <UpcMpcSearchDialogModal
                            sections={filterSection}
                            productType={PRODUCT_SECTION.VENDOR_PRODUCTS}
                            filterData={upcFilterData}
                            setFilterData={setUpcFilterData}
                            handleFilterClear={handleFilterClear}
                            onFilterSubmit={onFilterSubmit}
                            triggerButton={
                                <HoverButton variant="tertiaryContained"
                                    iconButton={<img src={advanceFilterIcon} alt="Filter Icon" />}
                                    label="Advance Filters"
                                    buttonWithImg={true}
                                    hoverButton={!!upcFilterData?.productIds?.length}
                                    hoverButtonLabel={upcFilterData?.productIds?.length}
                                    handleCloseIcon={clearAllData}
                                    size='medium'
                                />
                            }
                            advanceFilterType={ADVANCE_FILTER_TYPE.VENDOR_PRODUCTS}
                        />
                    </div>
                </div>

                <div className={`${classes.selectBox} rounded-xl p-4 grid gap-3`}>
                    {
                        productsData.map((product: IProductData, index) => (
                            <ProductViewTemplate
                                key={index}
                                product={product}
                                formik={formik}
                                showButton={true}
                                handleChange={handleChange}
                                showAttributes={true}
                            />

                        ))
                    }
                    {formik.touched?.productId && formik.errors?.productId &&
                        <FormHelperText error className='top-half '>{formik.errors?.productId}</FormHelperText>
                    }
                    <TablePagination
                        className={`${classes.paginationTable} w-full mt-4 flex`}
                        component="div"
                        rowsPerPageOptions={[5, 10, 15]}
                        count={pagination.totalRecords}
                        page={pagination.page}
                        onPageChange={handlePaginationChange}
                        rowsPerPage={pagination.size}
                        ActionsComponent={TablePaginationActions}
                        onRowsPerPageChange={handleRowsPerPageChange}
                    />
                </div>

            </div>
            <div className=' flex justify-between'>
                <Button
                    variant="tertiaryContained"
                    label="Cancel"
                    onClick={handleCancel}
                    size='medium'
                />
                <div className='flex gap-6 '>
                    <Button
                        variant="tertiaryContained"
                        label="Back"
                        onClick={handleBack}
                        size='medium'
                    />
                    <Button
                        variant="primaryContained"
                        label="Next"
                        onClick={onNext}
                        size='medium'
                    />
                </div>
            </div>
        </div>
    )
}

export default SelectProductTemplate