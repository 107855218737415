import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button/Button';
import { ADD_VENDOR_INVENTORY_STATES, HTTP_STATUS } from '../../../../utils/types';
import { useNavigate } from 'react-router-dom';
import ProductViewTemplate from './ProductView.template';
import AddInventorysectionTemplate from './AddInventorysection.template';
import { AddVendorInventorySectionProp } from './AddNewVendorInventory.template';
import { IProductData } from './SelectProduct.template';
import { useVendorInventoryService } from '../../../../services/useVendorInventoryService';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';

const useStyles = createUseStyles((theme: any) => ({
    webContainer: {
        display: "grid"
    },
    activatedProgressBar: {
        backgroundColor: theme.palette.backgroundV2.secondary.secondary950
    },
    activeProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary400
    },
    UnTouchProgressBar: {
        backgroundColor: theme.palette.borderV2.tertiary.tertiary100
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    textHeading: {
        color: theme.palette.textV2.primary.primary950
    },
}));

const VendorInventoryReviewTemplate: React.FC<AddVendorInventorySectionProp> = ({ formik, setCurrentSectionTo }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [productsData, setProductsData] = useState<IProductData | null>(null);
    const vendorInventoryService = useVendorInventoryService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const { user } = useAuthenticatedUser();
    const handleCancel = () => {
        navigate('/dashboard/inventory-management');
    };
    const handleBack = () => {
        setCurrentSectionTo(ADD_VENDOR_INVENTORY_STATES.ADD_USN);
    }

    const getVedorProductbyId = async () => {
        if(user?.businessId){
            try {
                const productId = formik?.values?.productId ?? 0;
                const ProductsResponse = await vendorInventoryService.getDetailedProduct(user?.businessId, productId);
                if (ProductsResponse.status === HTTP_STATUS.OK) {
                    const productData = ProductsResponse?.data?.data;
                    setProductsData({
                        id: productData.id,
                        productId: productData.productId,
                        skuCode: productData.standardProductDetails.upcCode,
                        productName: productData.productUpcTitle,
                        standardProductDetails: productData.standardProductDetails
                    });
                }
            } catch (error) {
                showSnackbar('error', 'Business Profile fetch failed');
            }
        }
        
    };
    useEffect(() => {
        getVedorProductbyId();
    }, [user?.businessId]);
    return (
        <div className={`${classes.webContainer} grid gap-y-8 `}>
            <div className={`w-full m-auto flex justify-between gap-x-2 text-var(--black, #4D4D4D) text-center text-sm `}>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activatedProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto font-semibold`}>Select Product Type </div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activatedProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Select Product</div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activatedProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Add USN Details </div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Review</div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>

            <div className=' grid gap-4 '>
                <div className='flex  justify-between'>
                    <div className=' grid gap-1/2'>
                        <text className={`${classes.textHeading} text-base font-medium`}> Review</text>
                        <text className={`${classes.barHeading} text-xs font-normal`}> Please review the data you have entered </text>
                    </div>
                </div>
                <ProductViewTemplate
                    product={productsData}
                    showButton={false}
                />
               <AddInventorysectionTemplate formik={formik} buttonShow={false} />

            </div>
            <div className=' flex justify-between'>
                <Button
                    variant="tertiaryContained"
                    label="Cancel"
                    onClick={handleCancel}
                />
            <div className='flex gap-6 '>
                <Button
                    variant="tertiaryContained"
                    label="Back"
                    onClick={handleBack}
                />
                {/* <Button
                    variant="secondaryContained"
                    label="Save As Draft"
                    onClick={handleBack}
                /> */}
                <Button
                    variant="primaryContained"
                    label="+Add"
                    onClick={()=> formik.handleSubmit()}
                />
            </div>
            </div>
        </div>
    )
}

export default VendorInventoryReviewTemplate